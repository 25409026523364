<template>
  <div v-if="loaded && slide" class="service-info-slide-container">
    <slide-heading
      :html-heading="slide.heading"
    />

    <div v-if="slide.image_urls.length && slide.description">
      <slide-image-and-description :slide="slide" />
    </div>

    <div v-else-if="slide.description">
      <div class="description">
        <!-- eslint-disable vue/no-v-html -->
        <div v-html="slideDescription" />
        <!--eslint-enable-->
      </div>
    </div>

    <div v-else-if="slide.image_urls.length">
      <div class="image-vert">
        <img :src="imageUrl" :alt="slide.image_alt_text">
      </div>
    </div>

    <domain-service-info
      v-if="serviceType === 'business-domain'"
      :slide="slide"
      @next-slide="$emit('next-slide')"
    />

    <email-service-info
      v-else-if="serviceType === 'business-email'"
      @next-slide="$emit('next-slide')"
    />

    <web-hosting-info
      v-else-if="serviceType === 'business-website-hosting'"
      @next-slide="$emit('next-slide')"
    />

    <div v-else>
      <p>
        Unable to load Service info
      </p>
    </div>
  </div>

  <div v-else>
    <div class="d-flex justify-content-center mt-2 mb-2">
      <ct-centered-spinner variant="primary" large />
    </div>
  </div>
</template>

<script>
import * as DOMPurify from 'dompurify'

export default {
  name: 'ServiceInfoSlide',
  components: {
    CtCenteredSpinner:        () => import('@/components/shared/CtCenteredSpinner'),
    DomainServiceInfo:        () => import('./services/DomainServiceInfo'),
    EmailServiceInfo:         () => import('./services/EmailServiceInfo'),
    WebHostingInfo:           () => import('./services/WebHostingInfo'),
    SlideImageAndDescription: () => import('@/components/StagelineV2/slides/SlideImageAndDescription'),
    SlideHeading:             () => import('@/components/StagelineV2/shared/SlideHeading'),
  },
  props: {
    slide: null,
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    imageUrl() {
      return this.slide.image_urls[0]
    },
    serviceType() {
      return this.slide.layout_sub_type
    },
    slideDescription() {
      return DOMPurify.sanitize(this.slide.description)
    },
  },
  mounted() {
    this.loaded = true
  },
}
</script>
<style scoped lang="scss">
</style>
